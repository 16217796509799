const BannerTwoIcon = [
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'},
    {icon:'star-fill'}
]

const BannerFourData = [
    
    {
        text:'Home',
        to:'#home'
    },
    {
        text:'Features',
        to:'#features'
    },
    {
        text:'Pricing',
        to:'#pricing'
    },
    {
        text:'About Us',
        to:'#cta'
    },
]

export {BannerFourData,BannerTwoIcon}
