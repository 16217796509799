const PricingListThree = [
    "6 Users",
    "24/7 Live Support",
    "Team Inbox",
    "Broadcast Message",
    "Message Template",
    "Import Contacts",
    "Tech Support",
    "Clean Design",
]


export { PricingListThree }