const FeatureOneList = [
    "Amazing feature for enhanced Porformance",
    "Meta Description Optimization",
    "High Performing Solutions To Transform",
]

const FeatureTwoIcon = [
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
    {icon: "star-fill"},
]

const FeatureThereeList = [
    "Where meetings come together in one.",
    "Workspaces forevery meeting.",
    "Amazing Feature.For Enhanced performance.",
]

export { FeatureOneList, FeatureTwoIcon, FeatureThereeList }
