import React from "react"
import { Container, Col, Row } from "reactstrap"
import { Link } from "../../components/button/Button"
import { Paragraph, TextBlock, TitleH2 } from "../../components/textBlock/TextBlock"
import { Section } from "../../layout/section/Section"


const CtaFour = (props) =>{
    return (
        <Section className ={props.className && props.className} id={props.id && props.id} >
            <Container>
                <Row className="justify-content-center text-center">
                    <Col xl="6" lg="7" md="8">
                        <TextBlock className="is-compact">
                            <TitleH2>About Us</TitleH2>
                            <Paragraph>Our vision is so simple. BLUCRIPT technology provides secure, efficient solutions for data protection and analysis, helping businesses operate smoothly and safely in a digital world.</Paragraph>
                            
                        </TextBlock>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default CtaFour
