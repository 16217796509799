import React from 'react'

const Pie = (props) => {
  return (
    <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
      <clipPath>
        <use overflow="visible"></use>
      </clipPath>
      <path d="M266.1 0c-5.8 0-10.4 4.8-10.4 10.7v234.4c0 5.9 4.7 10.7 10.4 10.7h229.2c5.8 0 10.4-4.8 10.4-10.7C505.6 109.8 398.4.1 266.1 0z" opacity="0.52"></path>
      <path d="M378.5 451.6L234.9 262.7V32c0-5.9-4.7-10.7-10.4-10.7C103.8 21.3 5.7 131.2 5.7 266.4 5.8 401.7 113 511.3 245.3 511.5c48 3.8 95.5-12.4 131.6-45.1 4.4-3.7 5.1-10.2 1.6-14.8z"></path>
      <path d="M495.3 277H287c-5.8 0-10.4 4.8-10.4 10.7 0 2.4.8 4.8 2.3 6.7l135.4 171.5c1.8 2.3 4.5 3.7 7.4 3.9h.7c2.6 0 5.2-1 7.1-2.9 48.5-46.4 76.1-111.3 76.2-179.3.1-5.8-4.6-10.6-10.4-10.6z" opacity="0.7"></path>
    </svg>
  )
}

export default Pie
