import React, { useState, useEffect } from 'react'
import LogoDrak2xx from '../../images/blucript-color.svg'
import LogoLight2xx from '../../images/blucript-white.svg'
import Menu from '../../layout/menu/Menu'
import MobileMenu from '../../layout/menu/MobileMenu'
import {Logo} from "../../components/logo/Logo"
import { Col, Container, Row } from "reactstrap"
import {Header, HeaderContent, HeaderMain, HeaderWrap} from "../../layout/header/Header"
import {Link} from "../../components/button/Button"
import { HeaderCaption, HeaderTitle } from '../../components/headerCaption/HeaderCaption'
import {BannerFourData} from './BannerData'
import META from '../../images/icon/meta/icons8-meta-240.png'
import FB from '../../images/icon/meta/icons8-fb-240.png'
import WAT from '../../images/icon/meta/icons8-whatsapp-240.png'
import INSTA from '../../images/icon/meta/icons8-instagram-240.png'
import MSG from '../../images/icon/meta/icons8-messenger-240.png'

const BannerFour = (props) =>{
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
        setOffset(window.pageYOffset)
        }
        window.scrollTo(0,0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
        setMobileView(true);
        } else {
        setMobileView(false);
        }
    };
    
    
    return(
        <Header className={props.className && props.className} id={props.id && props.id} >
            <HeaderMain className={`header-main header-main-s1 is-sticky is-transparent on-dark ${offset > 0 ? "has-fixed" : ""}`}>
                <Container className='header-container'>
                    <HeaderWrap>
                        <div className="header-logo">
                        <Logo to="/" 
                            dark ={LogoDrak2xx}
                            light ={LogoLight2xx}
                            />
                        </div>
                        <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                            <button className={`menu-toggler ${toggle === true ? "active" : ""}`}> 
                                <em className="menu-on icon ni ni-menu"></em>
                                <em className="menu-off icon ni ni-cross"></em>
                            </button>
                        </div>
                        <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}> 
                            {!mobileView ? <Menu className="ms-lg-auto" data={BannerFourData} /> : <MobileMenu data={BannerFourData}/>}
                            <ul className="menu-btns">
                                <li>
                                    <Link to="/" target="_blank" rel="noreferrer" className="btn-primary btn-lg">Download App</Link>
                                </li>
                            </ul>
                        </nav>
                        {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
                    </HeaderWrap>
                </Container>
            </HeaderMain>
            <HeaderContent className="py-6 is-dark mt-lg-n1 mt-n3 ">
                <Container className='header-text'>
                    <Row className='row justify-content-center text-center g-gs'>
                        <Col lg="8" md="5">
                            <HeaderCaption>
                                <HeaderTitle>Simple Steps To Empower Your Customer Experience.</HeaderTitle>
                                <p>A Single window for your business to create your owner methods to do marketing. Blucript comes with all kind of solutions for you business.</p>
                                <ul className="header-action btns-inline py-3">
                                    <li>
                                        <Link to="/" target="_blank" rel="noreferrer" className="btn-primary btn-lg"><span>Get Started</span></Link>
                                    </li>
                                    <li>
                                        <Link to="/" target="_blank" rel="noreferrer" className="btn-danger btn-lg"><span>View Demo</span></Link>
                                    </li>
                                </ul>
                                <ul className="header-icon list-inline pt-1">
                                    <li><img className="social-media-icons" src={WAT} alt="icon" /></li>
                                    <li><img className="social-media-icons" src={FB} alt="icon" /></li>
                                    <li><img className="social-media-icons" src={MSG} alt="icon" /></li>
                                    <li><img className="social-media-icons" src={INSTA} alt="icon" /></li>
                                    <li><img className="social-media-icons" src={META} alt="icon" /></li>
                                </ul>
                            </HeaderCaption>
                        </Col>
                    </Row>
                </Container>
            </HeaderContent>
        </Header>
    )
}

export default BannerFour
